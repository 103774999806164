import React from 'react';
import { List, Edit, Create, Datagrid, TextField, BooleanInput, EditButton, DisabledInput, SimpleForm, TextInput } from 'react-admin';

export const BrokerList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="brokerId" />
      <TextField source="identityRofexId" />
      <TextField source="slug" />
      <TextField source="name" />
      <TextField source="isXOMS" />
      <TextField source="showByma" />
      <TextField source="baseUrl" />
      <TextField source="username" />
      <TextField source="key" />
      <TextField source="visible" />
      <EditButton />
    </Datagrid>
  </List>
);

const BrokerTitle = ({ record }) => {
    return <span>Broker {record ? `"${record.name}"` : ''}</span>;
};

export const BrokerEdit = (props) => (
    <Edit title={<BrokerTitle />} {...props}>
      <SimpleForm>
        <DisabledInput source="id" />
        <TextInput source="brokerId" />
        <TextInput source="identityRofexId" />
        <TextInput source="slug" />
        <TextInput source="name" />
        <BooleanInput source="visible" />
        <BooleanInput source="isXOMS" />
        <BooleanInput source="showByma" />
        <TextInput source="baseUrl" />
        <TextInput source="websocketUrl" />
        <TextInput source="adminUrl" />
        <TextInput source="termsUrl" />
        <TextInput source="proprietary" />
        <TextInput source="marketOrderType" />
        <TextInput source="username" />
        <TextInput source="key" />
      </SimpleForm>
    </Edit>
);

export const BrokerCreate = (props) => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="brokerId" />
        <TextInput source="identityRofexId" />
        <TextInput source="slug" />
        <TextInput source="name" />
        <BooleanInput source="visible" />
        <BooleanInput source="isXOMS" />
        <BooleanInput source="showByma" />
        <TextInput source="baseUrl" />
        <TextInput source="websocketUrl" />
        <TextInput source="adminUrl" />
        <TextInput source="proprietary" />
        <TextInput source="marketOrderType" />
        <TextInput source="username" />
        <TextInput source="key" />
      </SimpleForm>
    </Create>
);
